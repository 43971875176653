import { createRouter, createWebHistory } from 'vue-router'
import { useTokenData } from '@/store'; 
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import ('@/views/HomeView.vue') ,
    meta: { title: '首页' }
  },{
    path: '/login',
    component: () => import ('@/views/LoginView.vue'),
    meta: { title: '登录' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 设置全局导航守卫
router.beforeEach((to, from, next) => {
  const store = useTokenData;  // 获取 Vuex store
  const isAuthenticated = store.getters.isAuthenticated;  // 检查用户是否已登录

  // 如果用户未登录，并且访问的不是登录页，则跳转到登录页
  if (!isAuthenticated && to.path !== '/login') {
      next('/login');  // 重定向到登录页面
  } else {
      next();  // 继续导航
  }
});

export default router
