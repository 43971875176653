<template>
  <router-view />
  <HeaderView v-if="isLoggedIn" />
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { useTokenData } from '@/store';

export default {
  components: {
    HeaderView: defineAsyncComponent(() => import('@/views/HeaderView.vue'))
  },
  computed: {
    isLoggedIn() {
      const store = useTokenData; // 获取 store
      return store.getters.isAuthenticated; // 返回认证状态
    }
  }
}
</script>

<style lang="scss">
body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #1e1e1e;
  color: #ffffff;
  font-family: Arial, sans-serif;
}
</style>
