import { createStore } from 'vuex'


const useTokenDataStore = createStore({
    state: {
        accessToken: localStorage.getItem('accessToken') || null,
        role: localStorage.getItem('role') || null,
        username: localStorage.getItem('username') || null,
        subscription: localStorage.getItem('subscription') || null,
        describe: localStorage.getItem('describe') || null
    },
    getters: {
        isAuthenticated: state => !!state.accessToken,
        getAccessToken: state => state.accessToken,
        getRole: state => state.role,
        getUsername: state => state.username,
        getSubscription: state => state.subscription,
        getDescribe: state => state.describe
    },
    mutations: {
        setAccessToken(state, token) {
            state.accessToken = token;
        },
        setRole(state, role) {
            state.role = role;
        },
        clearAuthData(state) {
            state.accessToken = null;
            state.role = null;
            state.username = null;
            state.subscription = null;
            state.describe = null;
        },
        setUsername(state, username) {
            state.username = username;
        },
        setSubscription(state, subscription) {
            state.subscription = subscription;
        },
        setDescribe(state, describe) {
            state.describe = describe;
        }
    },
    actions: {
        login({ commit }, { token, role, username, subscription, describe }) {
            commit('setAccessToken', token);
            commit('setRole', role);
            commit('setUsername', username);
            commit('setSubscription', subscription);
            commit('setDescribe', describe);

            // 将数据存储到 localStorage
            localStorage.setItem('accessToken', token);
            localStorage.setItem('role', role);
            localStorage.setItem('username', username);
            localStorage.setItem('subscription', subscription);
            localStorage.setItem('describe', describe);
        },
        logout({ commit }) {
            commit('clearAuthData');

            // 清除 localStorage 中的相关数据
            localStorage.removeItem('accessToken');
            localStorage.removeItem('role');
            localStorage.removeItem('username');
            localStorage.removeItem('subscription');
            localStorage.removeItem('describe');
        }
    },
    modules: {}
});


export default useTokenDataStore;